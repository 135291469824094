<template>
  <div role="tab-inner">
    <nav class="level">
      <div class="level-left" style="max-width: 75%">
        <div class="is-flex-direction-column">
          <b-field label="施設" class="has-check">
            <EnumCheckboxPicker
              :members="FACILITY_OPTIONS"
              v-model="tableFilter.facilities"
            />
          </b-field>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item buttons">
          <b-button
            icon-left="plus-circle"
            type="is-info"
            size="is-small"
            @click="willCreate"
            >日祝・特別レッスン新規作成</b-button
          >
        </div>
      </div>
    </nav>
    <LessonsTable
      :loading="loading"
      :tLessons="tLessons"
      :tableFilter="tableFilter"
      :area="area"
      @willEdit="willEdit"
    />
    <br />

    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <b-button
            label="CSVダウンロード"
            icon-left="cloud-download"
            @click="willCsvDownload"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { ApiLesson } from '@/api'
import { LessonTableFilter } from '@/types/typeLesson'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { Enums, TypeEnum } from '@/types/enum'
import LessonsTable from '@/components/Schedule/Lessons/LessonsTable.vue'
import LessonFormVue from '@/components/Schedule/Lessons/LessonForm.vue'
import EnumCheckboxPicker from '@/components/EnumCheckboxPicker.vue'
export default {
  mixins: [BaseMixin],
  components: { LessonsTable, EnumCheckboxPicker },
  props: {
    area: TypeEnum,
    loading: Boolean
  },
  computed: {
    FACILITY_OPTIONS () {
      return Enums.LessonFacility.list.filter(f => f.options.area === this.area.value)
    },
    ...mapState('storeSchedule', ['periodYm']),
    ...mapGetters('storeSchedule', {
      tLessons: 'spotLessons'
    })
  },
  data () {
    return {
      tableFilter: new LessonTableFilter(),
    }
  },
  methods: {
    ...mapMutations('storeCheckin', {
      clearCheckinData: 'clearData'
    }),
    ...mapMutations('storeSchedule', {
      addStoreLesson: 'addLessons'
    }),
    willCreate () {
      console.log('willCreate')
      this.$buefy.modal.open({
        parent: this,
        component: LessonFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { area: this.area, facility: this.tableFilter.facilities.length ? this.tableFilter.facilities[0] : null },
        events: {}
      })
    },
    willEdit (tLesson) {
      console.log(tLesson, 'willEdit')
      this.$buefy.modal.open({
        parent: this,
        component: LessonFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { area: this.area, tLesson },
        events: {}
      })
    },
    willCsvDownload () {
      console.log('willCsvDownload')
      this.$buefy.snackbar.open({
        message: '日祝・特別レッスン一覧CSVのダウンロードを開始しました。',
        type: 'is-success',
        position: 'is-bottom-left',
        duration: 3000
      })
      const link = document.createElement('a')
      link.href = ApiLesson.csvLessonsUri(this.periodYm, this.area.value)
      link.click()
    },
    resetFilter () {
      this.tableFilter = new LessonTableFilter(this.FACILITY_OPTIONS)
    }
  },
  mounted () {
    this.tableFilter.setFacilities(this.FACILITY_OPTIONS)
  }
}
</script>
