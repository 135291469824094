<template>
  <EnumPicker
    :members="FACILITY_OPTIONS"
    :grouped="true"
    :grouped-multiline="true"
    v-model="valueAlt"
  />
</template>

<script>
import { Enums, TypeEnum } from '@/types/enum'
import EnumPicker from '../EnumPicker.vue'
export default {
  components: { EnumPicker },
  props: {
    area: TypeEnum,
    value: Object
  },
  computed: {
    FACILITY_OPTIONS () {
      return Enums.LessonFacility.list.filter(f => f.options.area === this.area.value)
    },
    valueAlt: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }

}
</script>

<style>
</style>