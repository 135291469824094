<template>
  <b-table
    :loading="loading"
    :paginated="paginated"
    :per-page="perPage"
    :striped="true"
    :default-sort="defaultSort"
    :data="rows"
    :row-class="(row, index) => row.rowClass"
    style="font-size: 0.9em"
  >
    <b-table-column width="1em" v-slot="{ row }">
      <HolidayIcon :date="row.startDate" :area="area" />
    </b-table-column>
    <b-table-column
      label="施設"
      width="7em"
      field="facilitySortVal"
      sortable
      v-slot="{ row }"
    >
      <span v-if="row.facility">{{ row.facility.options.shortLabel }}</span>
      <span v-else>-</span>
    </b-table-column>
    <b-table-column
      label="日程"
      width="16em"
      field="startDateSortVal"
      sortable
      v-slot="{ row }"
    >
      {{ row.startDate.format('YYYY/MM/DD (dd) HH:mm') }} -
      {{ row.endDate.format('HH:mm') }}
    </b-table-column>
    <b-table-column label="プログラム" v-slot="{ row }">
      <ScheduleProgramLabel :programId="row.programId" />
    </b-table-column>
    <b-table-column label="担当" width="10em" v-slot="{ row }">
      <InstructorsLabel
        :instructorIds="row.instructorIds"
        v-if="row.instructorIds.length"
      />
      <span v-else>-</span>
    </b-table-column>
    <b-table-column
      label="状況"
      width="6em"
      field="active"
      centered
      sortable
      v-slot="{ row }"
    >
      <b-tag v-if="row.didCheckined" type="is-info is-light">実施済み</b-tag>
      <b-tag v-else-if="row.active" type="is-light">有効</b-tag>
      <b-tag v-else type="is-dark">無効</b-tag>
    </b-table-column>
    <b-table-column
      label="備考"
      field="note"
      width="4em"
      centered
      sortable
      v-slot="{ row }"
    >
      <b-tooltip
        v-if="row.note"
        :label="row.note"
        type="is-dark"
        position="is-left"
      >
        <b-icon icon="comment-outline" />
      </b-tooltip>
      <span v-else />
    </b-table-column>
    <b-table-column
      custom-key="actions"
      cell-class="is-actions-cell"
      width="5em"
      v-slot="{ row }"
    >
      <div class="buttons is-right">
        <b-button
          label="詳細"
          type="is-warning"
          size="is-small"
          icon-left="square-edit-outline"
          @click="$emit('willEdit', row)"
        />
      </div>
    </b-table-column>

    <section class="section" slot="empty">
      <div class="content has-text-grey has-text-centered">
        <template v-if="loading">
          <p>
            <b-icon icon="dots-horizontal" size="is-large" />
          </p>
          <p>Fetching data...</p>
        </template>
        <template v-else>
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>
  </b-table>
</template>

<script>
import { LessonTableFilter } from '@/types/typeLesson'
import HolidayIcon from '@/components/Shared/HolidayIcon.vue'
import { TypeEnum } from '@/types/enum'
import InstructorsLabel from '../InstructorsLabel.vue'
import ScheduleProgramLabel from '../ScheduleProgramLabel.vue'

export default {
  components: { HolidayIcon, InstructorsLabel, ScheduleProgramLabel },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tLessons: {
      type: Array,
      default: () => [],
    },
    tableFilter: {
      type: LessonTableFilter,
      default: null
    },
    area: TypeEnum,
  },
  data () {
    return {
      perPage: 10,
      defaultSort: 'startDateSortVal'
    }
  },
  computed: {
    paginated () {
      return this.rows.length > this.perPage
    },
    rows () {
      if (!this.tableFilter) {
        return this.tLessons
      }
      return this.tLessons.filter(row => this.tableFilter.test(row))
    },
  },
}
</script>
