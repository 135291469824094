<template>
  <b-tooltip
    v-if="mHoliday"
    :label="mHoliday.name"
    position="is-right"
    square
    type="is-danger"
  >
    <b-icon icon="shield-star-outline" type="is-danger" />
  </b-tooltip>
  <b-icon v-else-if="!blankIfNot" icon="shield-star-outline" />
  <span v-else />
</template>

<script>
import dayjs from 'dayjs'
import { TypeEnum } from '@/types/enum'
import { mapGetters } from 'vuex'
import { DateFormat } from '@/config/const'
export default {
  props: {
    date: dayjs.Dayjs,
    area: TypeEnum,
    blankIfNot: Boolean
  },
  computed: {
    ...mapGetters('storeHoliday', {
      findStoredMHoliday: 'findMHoliday'
    }),
    mHoliday () {
      const dateKey = this.date.format(DateFormat)
      return this.findStoredMHoliday(dateKey, this.area.value)
    }
  }
}
</script>

<style>
</style>