<template>
  <ProgramLabel
    :mProgram="mProgram"
    :mPGroup="mPGroup"
    :mParentGroup="mParentGroup"
    :labelProp="labelProp"
  />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    programId: Number,
    labelProp: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    label () {
      return this.mProgram ? this.mProgram[this.labelProp] : ''
    },
    ...mapGetters('storeSchedule', ['findMProgram', 'findMPGroup']),
    mProgram () {
      return this.findMProgram(this.programId)
    },
    mPGroup () {
      return this.mProgram ? this.findMPGroup(this.mProgram.groupId) : null
    },
    mParentGroup () {
      return this.mPGroup ? this.findMPGroup(this.mPGroup.parentId) : null
    },
  }
}
</script>

<style>
</style>