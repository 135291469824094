<template>
  <section>
    <b-table :data="value" narrowed v-if="value.length" :showHeader="false">
      <b-table-column :td-attrs="(row, column) => ({ width: '1em' })">
        <b-icon icon="human" />
      </b-table-column>
      <b-table-column v-slot="{ row }">
        {{ row.optionLabel }}
      </b-table-column>
      <b-table-column
        v-slot="{ row }"
        :td-attrs="(row, column) => ({ width: '1em' })"
      >
        <b-button
          icon-left="close"
          size="is-small"
          type="is-white"
          @click="remove(row)"
        />
      </b-table-column>
    </b-table>
    <b-autocomplete
      rounded
      v-model="inputText"
      :data="suggests"
      placeholder="検索"
      icon="magnify"
      clearable
      clearOnSelect
      openOnFocus
      field="optionLabel"
      @select="didSelect"
    >
      <template #empty>{{
        inputText ? '見つかりませんでした' : '氏名・かな・管理番号'
      }}</template>
    </b-autocomplete>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { MInstructor } from '@/types/typeInstructor' // eslint-disable-line no-unused-vars
export default {
  props: {
    value: {
      type: Array, // MInstructor
      default: () => []
    },
  },
  data () {
    return {
      defaultIds: this.value ? this.value.map(row => row.id) : [],
      inputText: ''
    }
  },
  computed: {
    ...mapState('storeSchedule', ['mInstructors']),
    selectedIds () {
      return this.value.map(row => row.id)
    },
    suggests () {
      if (!this.inputText) {
        return []
      }
      return this.mInstructors
        .filter(row => !this.selectedIds.includes(row.id))
        .filter(row => {
          if (!`${row.code}${row.kana}${row.name}`.includes(this.inputText)) {
            return false
          }
          if (this.defaultIds && this.defaultIds.includes(row.id)) {
            return true
          }
          return row.active
        })
    }
  },
  methods: {
    didSelect (row) {
      if (row) {
        this.$emit('input', this.value.concat([row]))
        this.inputText = ''
      }
    },
    remove (row) {
      this.$emit('input', this.value.filter(i => i !== row))
    }
  }
}
</script>
