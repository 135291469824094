<template>
  <div role="tab-inner">
    <nav class="level">
      <div class="level-left" style="max-width: 75%">
        <div class="block">
          <b-field label="施設" class="has-check">
            <EnumCheckboxPicker
              :members="FACILITY_OPTIONS"
              v-model="tableFilter.facilities"
            />
          </b-field>
          <b-field label="曜日" class="has-check">
            <RadioPicker
              noneLabel="全て"
              :options="WEEKS_OPTIONS"
              :grouped="true"
              :grouped-multiline="true"
              v-model="tableFilter.weekNoAlt"
            />
          </b-field>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item buttons">
          <b-button
            icon-left="plus-circle"
            type="is-info"
            size="is-small"
            @click="willCreate"
            >平日レッスン新規作成</b-button
          >
        </div>
      </div>
    </nav>
    <RepeatsTable
      :loading="loading"
      :tRepeats="tRepeats"
      :tableFilter="tableFilter"
      @willEdit="willEdit"
      @willEditDays="willEditDays"
    />
    <br />
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <b-button
            label="CSVダウンロード"
            icon-left="cloud-download"
            @click="willCsvDownload"
          />
        </div>
        <div class="level-item" v-if="shouldExpand">
          <b-button
            label="翌月にコピー"
            icon-left="expand-all-outline"
            type="is-danger"
            outlined
            @click="willExpand"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { ApiRepeat } from '@/api'
import { RepeatTableFilter } from '@/types/typeRepeat'
import { mapState } from 'vuex'
import { Enums, TypeEnum } from '@/types/enum'
import RepeatsTable from '@/components/Schedule/Repeats/RepeatsTable.vue'
import RepeatFormVue from '@/components/Schedule/Repeats/RepeatForm.vue'
import { WEEKS } from '@/config/const'
import RadioPicker from '@/components/RadioPicker.vue'
import EnumCheckboxPicker from '@/components/EnumCheckboxPicker.vue'
import RepeatExpandFormVue from '@/components/Schedule/Repeats/RepeatExpandForm.vue'
export default {
  mixins: [BaseMixin],
  components: { RepeatsTable, RadioPicker, EnumCheckboxPicker },
  props: {
    area: TypeEnum,
    loading: Boolean,
    expandable: Boolean
  },
  computed: {
    WEEKS_OPTIONS: () => WEEKS,
    FACILITY_OPTIONS () {
      return Enums.LessonFacility.list.filter(f => f.options.area === this.area.value)
    },
    ...mapState('storeSchedule', ['periodYm', 'tRepeats']),
    shouldExpand () {
      return this.tRepeats.length > 0 && this.expandable
    },
  },
  data () {
    return {
      tableFilter: new RepeatTableFilter(),
    }
  },
  methods: {
    willCreate () {
      console.log('willCreate')
      const { weekNo } = this.tableFilter
      this.$buefy.modal.open({
        parent: this,
        component: RepeatFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: {
          area: this.area, weekNo, facility: this.tableFilter.facilities.length ? this.tableFilter.facilities[0] : null },
        events: {}
      })
    },
    willEditDays (tRepeat) {
      this.willEdit(tRepeat, 1)
    },
    willEdit (tRepeat, tabIdx) {
      console.log(tRepeat, 'willEdit')
      this.$buefy.modal.open({
        parent: this,
        component: RepeatFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { area: this.area, tabIdx: tabIdx || 0, tRepeat },
        events: {}
      })
    },
    willCsvDownload () {
      console.log('willCsvDownload')
      this.$buefy.snackbar.open({
        message: '平日レッスン一覧CSVのダウンロードを開始しました。',
        type: 'is-success',
        position: 'is-bottom-left',
        duration: 3000
      })
      const link = document.createElement('a')
      link.href = ApiRepeat.csvRepeatsUri(this.periodYm, this.area.value)
      link.click()
    },
    resetFilter () {
      this.tableFilter = new RepeatTableFilter(this.FACILITY_OPTIONS)
    },
    willExpand () {
      console.log('willExpand')
      this.$buefy.modal.open({
        parent: this,
        component: RepeatExpandFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { area: this.area },
        events: {
          copied: () => {
            this.$emit('toNextYm')
          }
        }
      })
    }
  },
  mounted () {
    this.tableFilter.setFacilities(this.FACILITY_OPTIONS)
  }
}
</script>
