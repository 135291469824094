<template>
  <b-field grouped group-multiline>
    <div class="control" v-if="noneLabel">
      <b-checkbox
        v-model="newValue"
        :native-value="null"
        @input="input"
        :type="type"
        :disabled="disabled"
      >
        {{ noneLabel }}
      </b-checkbox>
    </div>
    <div v-for="member in members" class="control" :key="member.value">
      <b-checkbox
        v-model="newValue"
        :native-value="member"
        @input="input"
        :type="type"
        :disabled="disabled"
      >
        {{ member.label }}
      </b-checkbox>
    </div>
  </b-field>
</template>

<script>
export default {
  name: 'CheckboxPicker',
  props: {
    members: {
      type: Array,
      default: () => []
    },
    noneLabel: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    value: {
      type: Array,
      default: () => []
    },
    disabled: Boolean
  },
  data () {
    return {
      newValue: []
    }
  },
  created () {
    this.newValue = this.value
  },
  methods: {
    input () {
      this.$emit('input', this.newValue)
    }
  },
  watch: {
    value (newValue) {
      this.newValue = newValue
    }
  }
}
</script>
