<template>
  <b-field grouped group-multiline>
    <div
      v-for="mInstructor in mInstructors"
      class="control"
      :key="mInstructor.id"
    >
      <b-checkbox
        v-model="newValue"
        :native-value="mInstructor.id"
        @input="input"
        :type="type"
        :size="size"
      >
        {{ mInstructor.name }}
      </b-checkbox>
    </div>
  </b-field>
</template>

<script>
export default {
  name: 'CheckboxPicker',
  props: {
    mInstructors: Array,
    type: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'is-small'
    },
    disabled: Boolean,
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      newValue: []
    }
  },
  created () {
    this.newValue = this.value
  },
  methods: {
    input () {
      this.$emit('input', this.newValue)
    }
  },
  watch: {
    value (newValue) {
      this.newValue = newValue
    }
  }
}
</script>
