<template>
  <span>
    <template v-for="mInstructor in mInstructors">
      <InstructorLabel
        :mInstructor="mInstructor"
        :labelProp="labelProp"
        :key="`label${mInstructor.id}`"
      />
      <br :key="`br${mInstructor.id}`" />
    </template>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    instructorIds: Array,
    labelProp: {
      type: String,
      default: 'name'
    }
  },
  computed: {
    ...mapGetters('storeSchedule', ['findMInstructor']),
    mInstructors () {
      return this.instructorIds.map(id => this.findMInstructor(id)).filter(row => !!row)
    },
  }
}
</script>

<style>
</style>