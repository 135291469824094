<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <CardComponent
      :isScrollable="true"
      :title="cardTitle"
      icon="ballot"
      style="width: 1000px"
      headerIcon="close"
      hasBottomSlot
      @header-icon-click="$emit('close')"
    >
      <form>
        <b-tabs v-model="activeTab">
          <b-tab-item label="基本情報">
            <b-field label="曜日" class="has-check" horizontal>
              <ValidationField rules="required">
                <RadioPicker
                  :options="WEEKS_OPTIONS"
                  :grouped="true"
                  :grouped-multiline="true"
                  v-model="repeatForm.weekNo"
                  :disabled="daysFlowFlag"
                />
              </ValidationField>
            </b-field>
            <b-field label="施設" class="has-check" horizontal>
              <ValidationField rules="required">
                <FacilityRadio v-model="repeatForm.facility" :area="area" />
              </ValidationField>
            </b-field>
            <ProgramSelectField
              v-model="repeatForm.mProgramAlt"
              :facility="repeatForm.facility"
              :area="area"
              class="mb-3"
            />
            <b-field label="インストラクター" horizontal>
              <InstructorsSearch v-model="repeatForm.mInstructorsAlt" />
            </b-field>
            <b-field label="開始時刻" horizontal>
              <ValidationField rules="required" grouped>
                <StartTimePicker v-model="repeatForm.startTime" />
                <p class="control" v-if="repeatForm.calcEndTime">
                  <span class="button is-static">{{
                    repeatForm.calcEndTime.format('- HH : mm')
                  }}</span>
                </p>
              </ValidationField>
            </b-field>
            <b-field label="実施時間(分)" horizontal>
              <MinutesField
                v-model="repeatForm.minutes"
                rules="required"
                :presets="[30, 45, 60]"
              />
            </b-field>

            <b-field label="備考" horizontal>
              <b-input
                type="textarea"
                v-model="repeatForm.note"
                maxlength="500"
                :rows="2"
              />
            </b-field>
          </b-tab-item>
          <b-tab-item label="繰り返し設定" :disabled="!daysFlowFlag">
            <div
              role="tab-innner"
              style="min-height: 440px"
              v-if="daysFlowFlag"
            >
              <RepeatDaysTable
                ref="RepeatDaysTable"
                :repeatForm="repeatForm"
                :area="area"
                :loading="isLoading"
              />
            </div>
          </b-tab-item>
        </b-tabs>
      </form>

      <div slot="bottom" class="level p-4">
        <div class="level-left">
          <div class="level-item buttons">
            <b-button
              v-if="daysFlowFlag"
              native-type="button"
              type="is-primary"
              :loading="isLoading"
              @click.prevent="handleSubmit(willSave)"
              >登録</b-button
            >
            <b-button
              v-else
              native-type="button"
              type="is-info"
              outlined
              :disabled="!shouldDaysFlow"
              @click.prevent="willToDaysFlow"
              >繰り返し設定へ進む</b-button
            >
            <b-button
              type="is-primary is-outlined"
              @click="$emit('close')"
              :loading="isLoading"
              >キャンセル</b-button
            >
          </div>
        </div>
        <div class="level-right" v-if="!isCreate && !isLoading">
          <div class="level-item buttons">
            <b-tag v-if="tRepeat.didCheckined" type="is-info is-light"
              >実施済み有</b-tag
            >
            <b-button
              v-else
              native-type="button"
              type="is-danger"
              icon-right="delete"
              :loading="isLoading"
              @click.prevent="willDelete"
            />
          </div>
        </div>
      </div>
    </CardComponent>
  </ValidationObserver>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { RepeatForm, TRepeat } from '@/types/typeRepeat.js'
import { ApiRepeat, ApiRepeatLesson } from '@/api'
import { Enums, TypeEnum } from '@/types/enum'
import ProgramSelectField from '../ProgramSelectField.vue'
import MinutesField from '@/components/Shared/MinutesField.vue'
import { mapGetters, mapMutations } from 'vuex'
import { WEEKS } from '@/config/const'
import RadioPicker from '@/components/RadioPicker.vue'
import StartTimePicker from '../StartTimePicker.vue'
import RepeatDaysTable from './RepeatDaysTable.vue'
import FacilityRadio from '../FacilityRadio.vue'
import InstructorsSearch from '../InstructorsSearch.vue'
export default {
  mixins: [BaseMixin],
  components: { CardComponent, FacilityRadio, ProgramSelectField, MinutesField, RadioPicker, StartTimePicker, RepeatDaysTable, InstructorsSearch },
  props: {
    area: TypeEnum,
    weekNo: {
      type: Number,
      default: null,
    },
    facility: {
      type: TypeEnum,
      default: null,
    },
    tabIdx: {
      type: Number,
      default: 0,
    },
    tRepeat: TRepeat
  },
  computed: {
    WEEKS_OPTIONS: () => WEEKS,
    FACILITY_OPTIONS () {
      return Enums.LessonFacility.list.filter(f => f.options.area === this.area.value)
    },
    cardTitle () {
      const baseParts = `${this.area.label}平日レッスン設定`
      const { facility, weekNo, mProgram, startTime, calcEndTime } = this.repeatForm
      const weekParts = weekNo ? ` 毎週${WEEKS[weekNo]}曜日` : ''
      const timeParts = (startTime && calcEndTime) ? ` ${startTime.format('HH:mm')} ~ ${calcEndTime.format('HH:mm')}` : ''
      const programParts = mProgram ? ` 「${mProgram.name}」` : ''
      const locationParts = facility ? `@${facility.label}` : ''
      return `${baseParts} ${weekParts}${timeParts}${programParts}${locationParts}`
    },
    ...mapGetters('storeSchedule', ['currentPeriod', 'findMInstructors', 'findMProgram']),
    shouldDaysFlow () {
      const { weekNo, minutes, mInstructors } = this.repeatForm
      return !!weekNo && !!minutes && mInstructors.length > 0
    },
    isCreate () {
      return !this.tRepeat
    }
  },
  data () {
    return {
      activeTab: this.tabIdx,
      repeatForm: new RepeatForm(),
      daysFlowFlag: false,
    }
  },
  methods: {
    ...mapMutations('storeCheckin', {
      clearCheckinData: 'clearData'
    }),
    ...mapMutations('storeSchedule', {
      addStoreRepeat: 'addRepeat',
      editStoreRepeat: 'editRepeat',
      removeStoreRepeat: 'removeRepeat'
    }),
    willToDaysFlow () {
      this.daysFlowFlag = true
      this.$nextTick(() => {
        this.$refs.RepeatDaysTable.forgeDayForms()
        this.$nextTick(() => {
          this.activeTab = 1
        })
      })
    },
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        this.execSave(false).catch(error => {
          this.handleError(error, {
            onConfirm: () => {
              this.execSave(true)
            }
          })
        })
      }, 500)
    },
    execSave (confirmed) {
      return ApiRepeat.saveRepeat(this.currentPeriod.key, this.area.value, this.repeatForm, confirmed).then(({ tRepeat, tLessons }) => {
        if (this.isCreate) {
          this.addStoreRepeat({ tRepeat, tLessons })
          this.alertSuccess('平日レッスンを作成しました')
        } else {
          this.editStoreRepeat({ tRepeat, tLessons })
          this.alertSuccess('平日レッスンを編集しました')
        }
        this.clearCheckinData()
        this.$emit('close')
        this.isLoading = false
      })
    },
    willDelete () {
      console.log('willDelete')
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        confirmText: '削除する',
        cancelText: 'キャンセル',
        message: '平日レッスンを削除しますか？',
        onConfirm: () => {
          this.isLoading = true
          setTimeout(() => {
            ApiRepeat.deleteRepeat(this.tRepeat.id).then(() => {
              this.removeStoreRepeat({ repeatId: this.tRepeat.id })
              this.clearCheckinData()
              this.alertSuccess('平日レッスンを削除しました')
              this.$emit('close')
              this.isLoading = false
            }).catch(this.handleError)
          }, 500)
        }
      })
    }
  },
  watch: {
    shouldDaysFlow (bool) {
      if (!bool) {
        this.daysFlowFlag = false
      }
    }
  },
  created () {
    if (!this.tRepeat) {
      this.repeatForm.weekNo = this.weekNo || null
      this.repeatForm.facility = this.facility || null
      return
    }
    this.isLoading = true
    this.repeatForm.fill(this.tRepeat)
    this.repeatForm.mInstructors = this.findMInstructors(this.tRepeat.instructorIds)
    this.repeatForm.mProgram = this.findMProgram(this.tRepeat.programId)
    this.daysFlowFlag = this.shouldDaysFlow
    setTimeout(() => {
      ApiRepeatLesson.fetchRepeatLessons(this.tRepeat.id).then(({ tLessons }) => {
        this.repeatForm.fillRepeatLessons(tLessons)
        this.isLoading = false
      })
    }, 50)
  }
}
</script>
