<template>
  <b-field label="日程" horizontal>
    <ValidationField rules="required">
      <b-input :value="value.length ? 'on' : ''" class="is-hidden" />
      <b-field grouped group-multiline>
        <b-datepicker
          v-model="targetDates"
          multiple
          :events="barEvents"
          indicators="bars"
          :min-date="currentPeriod.minDay.toDate()"
          :max-date="currentPeriod.maxDay.toDate()"
          :focused-date="focusedDate"
        >
          <template v-slot:trigger>
            <b-button icon-left="calendar-today" type="is-warning" />
          </template>
        </b-datepicker>
        <div
          class="control"
          v-for="{ dateKey, dayObj, label, week } in tagInfos"
          :key="dateKey"
        >
          <b-taglist attached>
            <b-tag size="is-medium" type="is-light">{{
              dayObj.format('MM/DD(dd)')
            }}</b-tag>
            <b-tag size="is-medium" type="is-danger is-light" v-if="label">{{
              label
            }}</b-tag>
            <b-tag size="is-medium" type="is-dark is-light" v-else-if="week"
              >平日</b-tag
            >
          </b-taglist>
        </div>
      </b-field>
    </ValidationField>
  </b-field>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import { mapGetters } from 'vuex'
import { TypeEnum } from '@/types/enum'
import { DateFormat } from '@/config/const'

dayjs.locale(ja)

export default {
  props: {
    value: Array,
    area: TypeEnum,
  },
  computed: {
    targetDates: {
      get () {
        return this.value.map(dayObj => dayObj.toDate())
      },
      set (dates) {
        this.$emit('input', dates.map(date => dayjs(date)).sort((a, b) => a.isBefore(b) ? -1 : 1))
      }
    },
    focusedDate () {
      if (this.targetDates.length > 0) {
        return this.targetDates[this.targetDates.length - 1]
      }
      return this.currentPeriod.minDay.toDate()
    },
    ...mapGetters('storeHoliday', ['getAreaHolidays', 'findMHoliday']),
    ...mapGetters('storeSchedule', ['currentSundays', 'currentPeriod']),
    barEvents () {
      const holidays = this.getAreaHolidays(this.area.value).map(mHolidays => mHolidays.targetDate.toDate())
      const sundays = this.currentSundays.map(dayObj => dayObj.toDate())
      return [...holidays, ...sundays].map(date => {
        return {
          date,
          type: 'is-danger'
        }
      })
    },
    tagInfos () {
      return this.value.map(dayObj => {
        const dateKey = dayObj.format(DateFormat)
        const tagInfo = { dateKey, dayObj, label: null, week: false }
        const mHoliday = this.findMHoliday(dateKey, this.area.value)
        if (mHoliday) {
          tagInfo.label = mHoliday.name
        } else if (dayObj.day() !== 0) {
          tagInfo.week = true
        }
        return tagInfo
      })
    }
  }
}
</script>

<style>
</style>