<template>
  <CardComponent
    :isScrollable="true"
    :title="cardTitle"
    icon="ballot"
    headerIcon="close"
    hasBottomSlot
    @header-icon-click="$emit('close')"
  >
    <div class="is-relative" v-if="!loadStruct" style="min-height: 300px">
      <b-loading :is-full-page="false" active />
    </div>
    <div class="content" v-else-if="loadStruct.shouldExpand">
      <p>
        {{ area.label }}「{{ currentPeriod.label }}」の平日レッスン設定<b
          >{{ loadStruct.srcCount }}件</b
        >を翌月「{{ nextYmLabel }}」にコピーします。
      </p>
      <p v-if="loadStruct.overrideCount > 0" class="has-text-danger">
        すでに翌月に平日レッスン設定が<b>{{ loadStruct.overrideCount }}件</b
        >設定されています。コピーするとこれらは削除されます。
      </p>
      <p v-if="loadStruct.mHolidays.length">
        翌月の{{ area.label }}エリアには以下の祝日・休館日設定があります。
        <b-field grouped group-multiline>
          <div
            class="control"
            v-for="mHoliday in loadStruct.mHolidays"
            :key="mHoliday.id"
          >
            <b-taglist attached>
              <b-tag type="is-light">{{
                mHoliday.targetDate.format('MM/DD(dd)')
              }}</b-tag>
              <b-tag type="is-info is-light">{{ mHoliday.name }}</b-tag>
            </b-taglist>
          </div>
        </b-field>
        コピー後にこれらの日付のレッスンは【実施無し】になります。<br />祝日・休館日設定に漏れがないか確認してください。
      </p>
      <p v-else>
        翌月の{{
          area.label
        }}エリアに祝日・休館日設定は設定されていません。<br />祝日・休館日設定に漏れがないか確認してください。
      </p>
    </div>
    <div class="content" v-else>
      <b-message type="is-danger" has-icon>
        翌月{{ nextYmLabel }}にコピーすることはできません。<br />
        すでに平日レッスンの一部が実施確認されています。
      </b-message>
    </div>
    <div slot="bottom" class="level p-4">
      <div class="level-left">
        <div class="level-item buttons">
          <b-button
            native-type="button"
            type="is-primary"
            :loading="isLoading"
            :disabled="loadStruct && !loadStruct.shouldExpand"
            @click.prevent="willSave"
            >コピーする</b-button
          >
          <b-button
            type="is-primary is-outlined"
            @click="$emit('close')"
            :loading="isLoading"
            >キャンセル</b-button
          >
        </div>
      </div>
    </div>
  </CardComponent>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { TypeEnum } from '@/types/enum'
import { mapGetters } from 'vuex'
import { RepeatExpandForm } from '@/types/typeRepeat'
import { ApiRepeatExoand } from '@/api'
export default {
  mixins: [BaseMixin],
  components: { CardComponent },
  props: {
    area: TypeEnum
  },
  computed: {
    ...mapGetters('storeSchedule', ['currentPeriod']),
    nextYmLabel () {
      const { dayObj } = this.currentPeriod
      return dayObj.add(1, 'months').format('YYYY年MM月')
    },
    cardTitle () {
      return `${this.area.label} 平日レッスンコピー`
    },
  },
  data: () => {
    return {
      confirmed: false,
      loadStruct: null,
      expandForm: new RepeatExpandForm(),
    }
  },
  methods: {
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        return ApiRepeatExoand.expandRepeats(this.currentPeriod.key, this.area.value, this.confirmed).then(() => {
          this.alertSuccess('平日レッスンをコピーしました')
          this.$emit('copied')
          this.$emit('close')
          this.isLoading = false
        }).catch(this.handleError)
      }, 500)
    },
  },
  mounted () {
    this.isLoading = true
    ApiRepeatExoand.loadRepeatExpand(this.currentPeriod.key, this.area.value).then(struct => {
      this.loadStruct = struct
      this.confirmed = struct.overrideCount > 0
      this.isLoading = false
    }).catch(this.handleError)
  }
}
</script>
