<template>
  <div v-if="!calendarDataState" class="has-text-centered">
    <b-button
      @click="willForgeCalendarData"
      :loading="isLoading"
      type="is-info"
      size="is-medium"
      icon-left="cloud-download-outline"
      outlined
      >カレンダーを読み込む</b-button
    >
  </div>
  <div v-else>
    <EnumPicker
      :members="facilitys"
      :grouped="true"
      :grouped-multiline="true"
      v-model="facilityAlt"
      class="mt-2"
    />
    <div class="fc-wrap" style="margin-top: -3em">
      <div
        class="is-relative"
        v-if="loading || isLoading"
        style="min-height: 400px"
      >
        <b-loading :is-full-page="false" active />
      </div>
      <FullCalendar v-else ref="fullCalendar" :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import FullCalendar from '@fullcalendar/vue'
import jaLocale from '@fullcalendar/core/locales/ja'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import dayjs from 'dayjs'
import { Enums, TypeEnum } from '@/types/enum'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { TLesson } from '@/types/typeLesson' // eslint-disable-line no-unused-vars
import tippy from 'tippy.js'
import EnumPicker from '../EnumPicker.vue'
import LessonFormVue from './Lessons/LessonForm.vue'
import RepeatFormVue from './Repeats/RepeatForm.vue'

export default {
  mixins: [BaseMixin],
  props: {
    area: TypeEnum,
    loading: Boolean
  },
  components: {
    FullCalendar,
    EnumPicker
  },
  computed: {
    facilitys () {
      return Enums.LessonFacility.list.filter(f => f.options.area === this.area.value)
    },
    resources () {
      // return [{
      //   id: this.facilityAlt.value,
      //   title: this.facilityAlt.options.shortLabel,
      // }]
      return this.facilitys.map(f => {
        return {
          id: f.value,
          title: f.options.shortLabel,
        }
      })
    },
    ...mapState('storeSchedule', ['tLessons', 'calendarFacility', 'calendarDataState']),
    ...mapGetters('storeSchedule', ['currentPeriod', 'findRepeat', 'findMInstructors', 'findMProgram']),
    facilityAlt: {
      get () {
        return this.calendarFacility || this.facilitys[0]
      },
      set (val) {
        this.setCalendarFacility(val)
      }
    },
    initialDate () {
      const today = dayjs()
      const { minDay, maxDay } = this.currentPeriod
      if (today.isAfter(minDay) && today.isBefore(maxDay)) {
        return today.startOf('week').add(1, 'day') // LastSun +1 = Mon
      }
      return this.displayStartDay
    },
    events () {
      return this.tLessons.filter(row => row.active).map(row => {
        /** @type TLesson */
        const tLesson = row
        const classNames = ['t_lesson']
        if (tLesson.repeatId) {
          classNames.push('repeat')
        } else {
          classNames.push('holiday')
        }
        const mProgram = this.findMProgram(tLesson.programId)
        const mProgramParts = mProgram ? mProgram.name : ''
        const mInstructorParts = this.findMInstructors(tLesson.instructorIds).map(row => row.displayName).join(' / ')
        return {
          resourceId: tLesson.facility.value,
          title: `${mProgramParts}\n${mInstructorParts}`,
          start: tLesson.startDate.format(),
          end: tLesson.endDate.format(),
          classNames: classNames,
          lessonId: tLesson.id,
          description: `${mProgramParts}\n${mInstructorParts}`,
          mProgramParts,
          mInstructorParts,
          note: tLesson.note
        }
      })
    },
    displayStartDay () {
      const { minDay } = this.currentPeriod
      if (minDay.day() === 1) { // Mon
        return minDay
      }
      return minDay.startOf('week').add(1, 'day') // LastSun + 1 = Mon
    },
    displayEndDay () {
      const { maxDay } = this.currentPeriod
      if (maxDay.day() === 0) { // Sun
        return maxDay
      }
      return maxDay.endOf('week').add(1, 'day') // NextSat + 1 = Sunday
    },
    calendarOptions () {
      return {
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        plugins: [dayGridPlugin, interactionPlugin, resourceTimeGridPlugin],
        locales: [jaLocale],
        locale: 'ja',
        firstDay: 1, // Mon
        displayEventTime: false,
        eventDisplay: 'list-item',
        dayMaxEventRows: true,
        expandRows: true,
        // headerToolbar: { start: '', end: '' },
        // hiddenDays: [0],
        validRange: {
          start: this.displayStartDay.format('YYYY-MM-DD'),
          end: this.displayEndDay.add(1, 'day').format('YYYY-MM-DD')
        },
        height: '1300px',
        initialView: 'resourceTimeGridFourDay',
        datesAboveResources: true,
        views: {
          resourceTimeGridFourDay: {
            type: 'resourceTimeGrid',
            duration: { days: 1 },
            buttonText: 'Day'
          }
        },
        resources: this.resources,
        titleFormat: date => '',
        slotMinTime: '09:00:00',
        slotMaxTime: '23:00:00',
        allDaySlot: false,
        initialDate: this.initialDate.toDate(),
        dateIncrement: { days: 1 },
        events: this.events,
        // initialView: 'resourceTimeGridDay'
        eventContent: ({ event }) => {
          const { extendedProps } = event
          return { html: `
              <p>${extendedProps.mProgramParts}</p>
              <p>${extendedProps.mInstructorParts}</p>
            ` }
        },
        eventDidMount: ({ el, event }) => {
          const { extendedProps } = event
          tippy(el, {
            allowHTML: true,
            content: `
              <p>${extendedProps.mProgramParts}</p>
              <p>${extendedProps.mInstructorParts}<small class="ml-2">${extendedProps.note}</small></p>
            `,
          })
        },
        eventClick: ({ event }) => {
          this.didSelectLesson(event.extendedProps.lessonId)
        }
      }
    }
  },
  data () {
    return {
      didRenderd: false,
    }
  },
  methods: {
    ...mapMutations('storeSchedule', ['setCalendarFacility']),
    ...mapActions('storeSchedule', ['forgeCarendarData']),
    willForgeCalendarData () {
      this.isLoading = true
      this.forgeCarendarData({ area: this.area }).then(() => {
        this.isLoading = false
      })
    },
    rerenderCalendar () {
      if (!this.$refs.fullCalendar) {
        this.didRenderd = false
        return
      }
      if (this.didRenderd) {
        this.$nextTick(() => {
          this.$refs.fullCalendar.getApi().render()
        })
        return
      }
      this.isLoading = true
      this.$nextTick(() => {
        this.isLoading = false
        this.didRenderd = true
      })
    },
    resetFilter () {
      this.setCalendarFacility(null)
    },
    didSelectLesson (lessonId) {
      console.log(lessonId, 'didSelectLesson')
      /** @type {TLesson} */
      const tLesson = this.tLessons.find(row => row.id === lessonId)
      if (!tLesson) {
        console.log('selected tLesson not found')
        return
      }
      if (!tLesson.repeatId) {
        this.willEditLesson(tLesson)
        return
      }
      const tRepeat = this.findRepeat(tLesson.repeatId)
      if (!tRepeat) {
        console.log('selected tRepeat not found')
        return
      }
      this.willEditRepeat(tRepeat)
    },
    willEditLesson (tLesson) {
      console.log(tLesson, 'willEditLesson')
      this.$buefy.modal.open({
        parent: this,
        component: LessonFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { area: this.area, tLesson },
        events: {}
      })
    },
    willEditRepeat (tRepeat) {
      console.log(tRepeat, 'willEditRepeat')
      this.$buefy.modal.open({
        parent: this,
        component: RepeatFormVue,
        hasModalCard: true,
        canCancel: false,
        trapFocus: true,
        props: { area: this.area, tRepeat },
        events: {}
      })
    },
  },
}
</script>
