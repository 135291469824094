<template>
  <b-field label="日程" horizontal>
    <ValidationField rules="required">
      <nav class="level">
        <!-- Left side -->
        <div class="level-left">
          <div class="level-item">
            <b-datepicker
              v-model="targetDate"
              icon="calendar-today"
              :events="barEvents"
              indicators="bars"
              :min-date="currentPeriod.minDay.toDate()"
              :max-date="currentPeriod.maxDay.toDate()"
              :date-formatter="dateFormatter"
            >
            </b-datepicker>
          </div>
          <div class="level-item">
            <b-tag
              size="is-medium"
              type="is-danger is-light"
              v-if="tagInfo.label"
              >{{ tagInfo.label }}</b-tag
            >
            <b-tag
              size="is-medium"
              type="is-dark is-light"
              v-else-if="tagInfo.week"
              >平日</b-tag
            >
          </div>
        </div>
      </nav>
    </ValidationField>
  </b-field>
</template>

<script>
import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import { mapGetters } from 'vuex'
import { TypeEnum } from '@/types/enum'
import { DateFormat } from '@/config/const'

dayjs.locale(ja)

export default {
  props: {
    value: dayjs.Dayjs,
    area: TypeEnum,
  },
  computed: {
    targetDate: {
      get () {
        return this.value ? this.value.toDate() : null
      },
      set (jsDate) {
        this.$emit('input', jsDate ? dayjs(jsDate) : null)
      }
    },
    ...mapGetters('storeHoliday', ['getAreaHolidays', 'findMHoliday']),
    ...mapGetters('storeSchedule', ['currentSundays', 'currentPeriod']),
    barEvents () {
      const holidays = this.getAreaHolidays(this.area.value).map(mHolidays => mHolidays.targetDate.toDate())
      const sundays = this.currentSundays.map(dayObj => dayObj.toDate())
      return [...holidays, ...sundays].map(date => {
        return {
          date,
          type: 'is-danger'
        }
      })
    },
    tagInfo () {
      const tagInfo = { label: null, week: false }
      if (!this.value) {
        return tagInfo
      }
      const dateKey = this.value.format(DateFormat)
      const mHoliday = this.findMHoliday(dateKey, this.area.value)
      if (mHoliday) {
        tagInfo.label = mHoliday.name
      } else if (this.value.day() !== 0) {
        tagInfo.week = true
      }
      return tagInfo
    }
  },
  methods: {
    dateFormatter (date) {
      dayjs.locale(ja)
      return dayjs(date).format('YYYY/MM/DD(dd)')
    }
  }
}
</script>

<style>
</style>