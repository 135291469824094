<template>
  <CustomTimePicker
    rounded
    v-model="valueAlt"
    icon="clock"
    inline
    size="is-small"
    :min-time="minTime"
    :incrementMinutes="5"
    :incrementHours="1"
  >
  </CustomTimePicker>
</template>

<script>
import dayjs from 'dayjs'
import CustomTimePicker from './CustomTimePicker.vue'
export default {
  components: { CustomTimePicker },
  props: {
    value: dayjs.Dayjs
  },
  computed: {
    minTime () {
      const min = new Date()
      min.setHours(9)
      min.setMinutes(0)
      return min
    },
    valueAlt: {
      get () {
        return this.value ? this.value.toDate() : null
      },
      set (val) {
        this.$emit('input', val ? dayjs(val) : null)
      }
    },
  },
}
</script>

<style>
</style>