<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }" slim>
    <CardComponent
      :isScrollable="true"
      :title="cardTitle"
      icon="ballot"
      style="width: 1000px"
      headerIcon="close"
      hasBottomSlot
      @header-icon-click="$emit('close')"
    >
      <form>
        <TargetDatesField
          v-if="isCreate"
          v-model="lessonForm.targetDates"
          :area="area"
        />
        <TargetDateField v-else v-model="lessonForm.targetDate" :area="area" />
        <b-field label="施設" class="has-check" horizontal>
          <ValidationField rules="required">
            <FacilityRadio v-model="lessonForm.facility" :area="area" />
          </ValidationField>
        </b-field>
        <ProgramSelectField
          v-model="lessonForm.mProgramAlt"
          :facility="lessonForm.facility"
          :area="area"
          class="mb-3"
        />
        <b-field label="インストラクター" horizontal>
          <InstructorsSearch v-model="lessonForm.mInstructors" />
        </b-field>
        <b-field label="開始時刻" horizontal>
          <ValidationField rules="required" grouped>
            <StartTimePicker v-model="lessonForm.startTime" />
            <p class="control" v-if="lessonForm.calcEndTime">
              <span class="button is-static">{{
                lessonForm.calcEndTime.format('- HH : mm')
              }}</span>
            </p>
          </ValidationField>
        </b-field>
        <b-field label="実施時間(分)" horizontal>
          <MinutesField
            v-model="lessonForm.minutes"
            rules="required"
            :presets="[30, 45, 60]"
          />
        </b-field>

        <b-field label="備考" horizontal>
          <b-input
            type="textarea"
            v-model="lessonForm.note"
            maxlength="500"
            :rows="2"
          />
        </b-field>
        <b-field
          label="ステータス"
          horizontal
          v-if="!isCreate && !tLesson.didCheckined"
        >
          <b-switch v-model="lessonForm.active"> 有効 </b-switch>
        </b-field>
      </form>
      <div slot="bottom" class="level p-4">
        <div class="level-left">
          <div class="level-item buttons">
            <b-button
              native-type="button"
              type="is-primary"
              :loading="isLoading"
              @click.prevent="handleSubmit(willSave)"
              >登録</b-button
            >
            <b-button
              type="is-primary is-outlined"
              @click="$emit('close')"
              :loading="isLoading"
              >キャンセル</b-button
            >
          </div>
        </div>
        <div class="level-right" v-if="!isCreate">
          <div class="level-item buttons">
            <b-tag v-if="tLesson.didCheckined" type="is-info is-light"
              >実施済み</b-tag
            >
            <b-button
              v-else
              native-type="button"
              type="is-danger"
              icon-right="delete"
              :loading="isLoading"
              @click.prevent="willDelete"
            />
          </div>
        </div>
      </div>
    </CardComponent>
  </ValidationObserver>
</template>

<script>
import BaseMixin from '@/baseMixin'
import CardComponent from '@/components/CardComponent.vue'
import { LessonForm, TLesson } from '@/types/typeLesson.js'
import { ApiLesson } from '@/api'
import { TypeEnum } from '@/types/enum'
import ProgramSelectField from '../ProgramSelectField.vue'
import MinutesField from '@/components/Shared/MinutesField.vue'
import { mapGetters, mapMutations } from 'vuex'
import StartTimePicker from '../StartTimePicker.vue'
import FacilityRadio from '../FacilityRadio.vue'
import TargetDatesField from '../TargetDatesField.vue'
import TargetDateField from '../TargetDateField.vue'
import InstructorsSearch from '../InstructorsSearch.vue'
export default {
  mixins: [BaseMixin],
  components: { CardComponent, FacilityRadio, ProgramSelectField, MinutesField, StartTimePicker, TargetDatesField, TargetDateField, InstructorsSearch },
  props: {
    area: TypeEnum,
    facility: {
      type: TypeEnum,
      default: null
    },
    tLesson: TLesson
  },
  computed: {
    ...mapGetters('storeSchedule', ['findMInstructors', 'findMProgram']),
    cardTitle () {
      if (this.tLesson) {
        const { facility, targetDate, mProgram, startTime, calcEndTime } = this.tLesson
        const dateParts = targetDate ? targetDate.format('YYYY/MM/DD') : ''
        const timeParts = (startTime && calcEndTime) ? ` ${startTime.format('HH:mm')} ~ ${calcEndTime.format('HH:mm')}` : ''
        const programParts = mProgram ? ` 「${mProgram.name}」` : ''
        const locationParts = facility ? `@${facility.label}` : ''
        return `${this.area.label} ${dateParts}${timeParts}${programParts}${locationParts}`
      }
      const periodYmParts = this.currentPeriod ? this.currentPeriod.label : ''
      return `${this.area.label} ${periodYmParts} 日祝・特別レッスン作成`
    },
    isCreate () {
      return !this.tLesson
    },
    ...mapGetters('storeSchedule', ['currentPeriod']),
  },
  data: () => {
    return {
      lessonForm: new LessonForm(),
    }
  },
  methods: {
    ...mapMutations('storeCheckin', {
      clearCheckinData: 'clearData'
    }),
    ...mapMutations('storeSchedule', {
      addStoreLesson: 'addLessons',
      editStoreLesson: 'editLesson',
      removeStoreLesson: 'removeLesson'
    }),
    willSave () {
      this.isLoading = true
      setTimeout(() => {
        this.execSave(false).catch(error => {
          this.handleError(error, {
            onConfirm: () => {
              this.execSave(true)
            }
          })
        })
      }, 500)
    },
    execSave (confirmed) {
      if (this.isCreate) {
        return ApiLesson.createLessons(this.currentPeriod.key, this.area.value, this.lessonForm, confirmed).then(({ tLessons }) => {
          this.addStoreLesson({ tLessons })
          this.clearCheckinData()
          this.alertSuccess(`日祝・特別レッスンを${tLessons.length}件作成しました`)
          this.$emit('close')
          this.isLoading = false
        })
      }
      return ApiLesson.editLesson(this.lessonForm, confirmed).then(({ tLesson }) => {
        this.editStoreLesson({ tLesson })
        this.clearCheckinData()
        this.alertSuccess('日祝・特別レッスンを編集しました')
        this.$emit('close')
        this.isLoading = false
      })
    },
    willDelete () {
      console.log('willDelete')
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        confirmText: '削除する',
        cancelText: 'キャンセル',
        message: '日祝・特別レッスンを削除しますか？',
        onConfirm: () => {
          this.isLoading = true
          setTimeout(() => {
            ApiLesson.deleteLesson(this.tLesson.id).then(() => {
              this.removeStoreLesson({ lessonId: this.tLesson.id })
              this.clearCheckinData()
              this.alertSuccess('日祝・特別レッスンを削除しました')
              this.$emit('close')
              this.isLoading = false
            }).catch(this.handleError)
          }, 500)
        }
      })
    }
  },
  created () {
    if (this.tLesson) {
      this.lessonForm.fill(this.tLesson)
      this.lessonForm.mProgram = this.findMProgram(this.tLesson.programId)
      this.lessonForm.mInstructors = this.findMInstructors(this.tLesson.instructorIds)
    } else {
      this.lessonForm.facility = this.facility || null
    }
  }
}
</script>
