<template>
  <b-table
    :loading="loading"
    striped
    narrowed
    :data="repeatForm.repeatLessonForms"
  >
    <b-table-column label="実施日" width="8.4em" v-slot="{ row }">
      {{ row.targetDate.format('YYYY/MM/DD (dd)') }}
    </b-table-column>
    <b-table-column label="" width="1em" centered v-slot="{ row }">
      <HolidayIcon :area="area" :date="row.targetDate" blankIfNot />
    </b-table-column>
    <b-table-column label="インストラクター" v-slot="{ row }">
      <b-input
        v-if="row.noteEditable"
        v-model="row.note"
        size="is-small"
        rounded
        placeholder="備考を入力"
      />
      <InstructorChecks
        v-else
        v-model="row.instructorIds"
        :mInstructors="repeatForm.mInstructors"
        :disabled="row.didCheckined"
      />
    </b-table-column>
    <b-table-column label="備考" width="3em" v-slot="{ row }">
      <a
        v-if="row.noteEditable"
        class="has-text-dark"
        @click.prevent.stop="willToggleNote(row)"
      >
        <b-icon icon="close-circle" />
      </a>
      <b-tooltip
        v-else-if="row.note"
        :label="row.note"
        type="is-dark"
        position="is-left"
      >
        <a class="has-text-primary" @click.prevent.stop="willToggleNote(row)">
          <b-icon icon="comment-outline" />
        </a>
      </b-tooltip>
      <a
        v-else
        class="has-text-primary"
        @click.prevent.stop="willToggleNote(row)"
      >
        <b-icon icon="dots-horizontal" />
      </a>
    </b-table-column>
    <b-table-column label="実施" width="6em" centered v-slot="{ row }">
      <b-tag v-if="row.didCheckined" type="is-info is-light">済</b-tag>
      <b-switch v-else v-model="row.active" size="is-small"></b-switch>
    </b-table-column>

    <section class="section" slot="empty">
      <div class="content has-text-grey has-text-centered">
        <template v-if="loading">
          <p>
            <b-icon icon="dots-horizontal" size="is-large" />
          </p>
          <p>Fetching data...</p>
        </template>
        <template v-else>
          <p>
            <b-icon icon="emoticon-sad" size="is-large" />
          </p>
          <p>Nothing's here&hellip;</p>
        </template>
      </div>
    </section>
  </b-table>
</template>

<script>
import { RepeatLessonForm, RepeatForm } from '@/types/typeRepeat'
import { mapGetters } from 'vuex'
import { TypeEnum } from '@/types/enum'
import { DateFormat } from '@/config/const'
import HolidayIcon from '@/components/Shared/HolidayIcon.vue'
import { forgeWeekDays } from '@/util'
import InstructorChecks from '../InstructorChecks.vue'
export default {
  components: { HolidayIcon, InstructorChecks },
  props: {
    area: TypeEnum,
    repeatForm: RepeatForm,
    loading: Boolean,
  },
  computed: {
    ...mapGetters('storeHoliday', ['findMHoliday']),
    ...mapGetters('storeSchedule', ['currentPeriod']),
    targetDates () {
      if (!this.repeatForm.weekNo) {
        return []
      }
      const { minDay, maxDay } = this.currentPeriod
      return forgeWeekDays(minDay, maxDay, this.repeatForm.weekNo)
    },
  },
  methods: {
    forgeDayForms () {
      this.repeatForm.repeatLessonForms = this.targetDates.map(targetDate => {
        const repeatLessonForm = new RepeatLessonForm(targetDate)
        const dateKey = targetDate.format(DateFormat)
        const mHoliday = this.findMHoliday(dateKey, this.area.value)
        if (mHoliday) {
          repeatLessonForm.active = false
        }
        repeatLessonForm.instructorIds = this.repeatForm.mInstructors.map(row => row.id)
        return repeatLessonForm
      })
    },
    willToggleNote (row) {
      row.noteEditable = !row.noteEditable
    }
  }
}
</script>

<style>
</style>