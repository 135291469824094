<template>
  <div>
    <section class="hero is-hero-bar is-blue-hero">
      <div class="hero-body">
        <div class="level">
          <div class="level-left">
            <div class="level-item is-hero-content-item">
              <div style="padding: 0 2em">
                <h1 class="title is-spaced">
                  <b>{{ area.label }}</b
                  >スケジュール管理
                </h1>
                <h3 class="subtitle">
                  <b>
                    <a
                      href="#"
                      @click.prevent.stop="willChangePeriod(-1)"
                      v-if="shouldPrevPeriod"
                    >
                      <b-icon
                        type="is-dark"
                        icon="chevron-left"
                        custom-size="mdi-24px"
                      />
                    </a>
                    {{ currentPeriod.label }}
                    <a
                      href="#"
                      @click.prevent.stop="willChangePeriod(1)"
                      v-if="shouldNextPeriod"
                    >
                      <b-icon
                        type="is-dark"
                        icon="chevron-right"
                        custom-size="mdi-24px"
                      />
                    </a>
                  </b>
                </h3>
              </div>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item has-text-centered">
              <b-button type="is-light" @click="forgeTabsDatas"
                >再読み込み</b-button
              >
            </div>
            <div class="level-item">
              <b-dropdown :triggers="['hover']" aria-role="list">
                <template #trigger>
                  <b-button
                    label="エリア切替"
                    type="is-light"
                    icon-right="menu-down"
                  />
                </template>

                <b-dropdown-item
                  aria-role="listitem"
                  v-for="a in AREA_OPTIONS"
                  :key="a.value"
                  :disabled="area === a"
                >
                  <router-link
                    tag="span"
                    :to="{ name: 'Schedule', params: { pathArea: a.value } }"
                    >{{ a.label }}</router-link
                  >
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-main-section">
      <b-tabs
        v-model="activeTabAlt"
        type="is-boxed"
        class="is-sheeted"
        :animated="false"
      >
        <b-tab-item label="平日レッスン管理" icon="bookmark-multiple-outline">
          <Repeats
            ref="Repeats"
            :area="area"
            :loading="isLoading"
            :expandable="shouldNextPeriod"
            @toNextYm="willChangePeriod(1)"
          />
        </b-tab-item>
        <b-tab-item label="日祝・特別レッスン管理" icon="shield-star-outline">
          <Lessons ref="Lessons" :area="area" :loading="isLoading" />
        </b-tab-item>
        <b-tab-item label="カレンダー" icon="calendar">
          <LessonCalendar
            ref="LessonCalendar"
            :area="area"
            :loading="isLoading"
          />
        </b-tab-item>
      </b-tabs>
      <hr />
    </section>
  </div>
</template>

<script>
import BaseMixin from '@/baseMixin'
import { Enums } from '@/types/enum'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Lessons from './Schedule/Lessons.vue'
import Repeats from './Schedule/Repeats.vue'
import LessonCalendar from '@/components/Schedule/LessonCalendar.vue'
import { MonthKeyFormat } from '@/config/const'

export default {
  mixins: [BaseMixin],
  props: {
    pathArea: String,
  },
  components: { Lessons, Repeats, LessonCalendar },
  data () {
    return {
      activeTab: 0,
    }
  },
  computed: {
    ...mapState('storeSchedule', ['selectablePeriodYms']),
    ...mapGetters('storeSchedule', ['currentPeriod']),
    AREA_OPTIONS: () => Enums.Area.list,
    area () {
      return Enums.Area.of(this.pathArea)
    },
    activeTabAlt: {
      get () { return this.activeTab },
      set (val) {
        this.activeTab = val
        if (val === 2) {
          this.$refs.LessonCalendar.rerenderCalendar()
        }
      }
    },
    shouldPrevPeriod () {
      const prevYm = this.currentPeriod.dayObj.add(-1, 'months').format(MonthKeyFormat)
      return this.selectablePeriodYms.includes(prevYm)
    },
    shouldNextPeriod () {
      const nextYm = this.currentPeriod.dayObj.add(1, 'months').format(MonthKeyFormat)
      return this.selectablePeriodYms.includes(nextYm)
    },
  },
  methods: {
    ...mapMutations('storeSchedule', {
      clearStoreData: 'clearData',
      setStorePeriodYm: 'setPeriodYm',
    }),
    ...mapActions('storeSchedule', {
      forgeScheduleStates: 'forgeStates',
      forgeScheduleData: 'forgeData',
    }),
    ...mapActions('storeHoliday', {
      forgeHolidayStates: 'forgeStates',
    }),
    ...mapMutations('storeCheckin', {
      clearCheckinData: 'clearData'
    }),
    willChangePeriod (d) {
      this.setStorePeriodYm(this.currentPeriod.dayObj.add(d, 'months').format(MonthKeyFormat))
      this.forgeTabsDatas()
    },
    resetFilters () {
      this.$refs.Lessons.resetFilter()
      this.$refs.Repeats.resetFilter()
      this.$refs.LessonCalendar.resetFilter()
    },
    forgeTabsDatas () {
      this.clearStoreData()
      this.isLoading = true
      this.forgeScheduleData({ area: this.area }).then(() => {
        this.isLoading = false
      }).catch(this.handleError)
    }
  },
  created () {
    this.forgeScheduleStates(true).catch(this.handleError)
    this.forgeHolidayStates().catch(this.handleError)
  },
  mounted () {
    this.forgeTabsDatas()
  },
  beforeRouteUpdate (to, from, next) {
    console.log('beforeRouteUpdate')
    this.clearStoreData()
    next()
    this.$nextTick(() => {
      this.resetFilters()
      this.forgeTabsDatas()
    })
  }
}
</script>

<style>
.dropdown_area_change .disabled {
  cursor: initial;
  color: black;
}
</style>